import { Component, OnInit, Input, ViewEncapsulation } 				from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } 						from '@angular/common';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } 		from '../app.component';
import { AppService }			from './../app.service';
import { AudioService }			from './../audio/audio.service';

// jQuery
declare let jquery:any;
declare let $ :any;
declare let gtag :any;

@Component({
  selector: 'context',
  templateUrl: './context.component.html',
  styleUrls: ['./context.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class ContextComponent implements OnInit {
	private _chapter: any;
	@Input() contexts: any;
	scrollLeft: any = 0;
	contextOpen: any = false;
	delaycontextOpen: boolean = false;
	slideIndex: number = 0;
	scrollPos: string = "";
	mute: boolean;
	ck_next: any = false;
	ck_prev: any = false;
	hideNavCtxt: boolean;
	textCopied: boolean = false;
	contextMenu: any = false;
	totalContext: number;

	@Input()
	set chapter(chapter: any) {
		this._chapter = chapter;
	}

	constructor(private router: Router,
				private appService: AppService,
				private location: Location,
				private cookieService: CookieService,
				private audioService: AudioService
	) {}

	ngOnInit(): void {
	  	this.appService.getScroll().subscribe(item => {
	  		this.scrollLeft = item;
	  	});

	  	this.appService.getContext().subscribe(item => {
	  		this.contextOpen = item;
	  	});

	  	this.appService.getDelayCtxt().subscribe(item => {
	  		this.delaycontextOpen = item;
	  	});

	  	this.appService.getMute().subscribe(item => {
	  		this.mute = item;
	  	});

	  	this.appService.getBtnCtxt().subscribe(item => {
	  		this.hideNavCtxt = item;
	  	});

	  	this.appService.getContextMenu().subscribe(item => {
	  		this.contextMenu = item;
	  	});

	  	this.countContext();
	}

	copyLink(): void {
		let copyText = document.getElementById("copyText") as HTMLInputElement;

		copyText.select();

		document.execCommand("copy");
		this.textCopied = true;

		setTimeout(() => {
			this.textCopied = false;
		}, 5000);
	}

	getHref(html): any {
		let href= html.substring(html.indexOf('href')+6);
		href= href.substring(0, href.indexOf('”'));
		return this.appService.sanitizeUrl(href);
	}

	getLinkText(html): any {
		let text = html.substring(html.indexOf('>')+1);
		text = text.substring(0, text.indexOf('<'));
		return text;
	}

	changeContext(id): void {
		this.appService.toggleContext(id);
	}

	toggleContext(): void {
		if(!this.contextOpen) {
			this.openContext(1);
		} else {
			this.closeContext();
		}
	}

	openContext(id): void {
		if(id) {
			// call for analitycs
		  	this._chapter.context[id-1].read = true;
		  	
			this.appService.toggleContext(id);
			// mute sound
			this.audioService.controlSound(true);
			// create video embed
			setTimeout(() => { 
				this.appService.loadIframe();
				$('#context-content').scrollTop(0);

				this.location.replaceState('chapter/'+this._chapter.id+'/context/'+id);
				gtag('config', 'UA-141780583-1', {
			      'page_title' : 'Chapter '+this._chapter.id,
			      'page_path': 'chapter/'+this._chapter.id+'/context/'+id
			    });
				// load tooltip
				this.appService.loadCxtTooltip();
				setTimeout(() => { 
					this.appService.setDelayCtxt(true);
				}, 300);
			}, 100);
		}
	}

	closeContext(): void {
		let id = this.contextOpen;
		this.appService.setDelayCtxt(false);
		this.appService.toggleContext(false);
		this.appService.setBtnCtxt(false);
		this.appService.setContextMenu(false);
		this.location.replaceState('chapter/'+this._chapter.id);

		// unmute sound or not
		this.audioService.controlSound(this.mute);
	}

	scrollHandler(e): void {
		if(e.target.scrollTop === 0) {
			this.scrollPos = "top";
		} else if(e.target.scrollTop >= "250"){
			this.scrollPos = "bottom";
			this.loadNavTooltip();
		} else {
			this.scrollPos = "";
		}
	}

	loadNavTooltip(): void {
  		// show tooltip and create cookie for next event button
		this.ck_next = (this.cookieService.get("next-event") == "true" ? true : false);
		if(!this.ck_next) {
			setTimeout(() => { 
				this.appService.showTooltip('next-event',3);
			}, 1000);
		}

		// show tooltip and create cookie for previous event button
		this.ck_prev = (this.cookieService.get("previous-event") == "true" ? true : false);
		if(!this.ck_prev) {
			setTimeout(() => { 
				this.appService.showTooltip('previous-event',3);
			}, 1000);
		}
  	}

	slideFresco(dir): void {
		let cid,
			tid;

		tid = parseInt(this.contextOpen) + parseInt(dir);
		if(this.contextMenu) {
			cid = this.contextMenu[0];
			tid = parseInt(this.contextMenu[1]) + parseInt(dir);
			if(tid > this.contexts[cid-1].length) {
				tid = 1;
				cid = parseInt(cid) + parseInt(dir);
				// load iframe after changing chpater
				setTimeout(() => { 
					this.appService.loadIframe();
				}, 100);
			} else if(tid == 0) {
				cid = this.contextMenu[0] + parseInt(dir);
				tid = this.contexts[cid-1].length;
				// load iframe after changing chpater
				setTimeout(() => { 
					this.appService.loadIframe();
				}, 100);
			}

			this.appService.setContextMenu([cid,tid]);
		} 

		this.appService.toggleContext(tid);
		this.appService.handleMove(this.contextOpen,this.contextMenu);
	}

	gotoChapter(id): void {
		this.closeContext();
		this.appService.setChapter(id-1);
		this.location.replaceState('chapter/'+id);
	}

	countContext(): void {
		let count = 0;
		for(let context of this.contexts) {
			count += context.length;
		}
		this.totalContext = count;
	}
}
