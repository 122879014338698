import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
export class HammerConfig extends HammerGestureConfig {
  /*overrides = <any> {
    swipe: { direction: 30 },
  };*/
  overrides = {
      pan: {
        direction: 30
      },
      swipe: {
        direction: 30
      },
      pinch: {
        enable: false
      },
      rotate: {
        enable: false
      }
  };
}