import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent }   from './app.component';
import { ChapterComponent } from './chapter/chapter.component';

const routes: Routes = [
	/*{ path: '', redirectTo: '/en', pathMatch: 'full' },
	{ path: ':lang',    component: AppComponent },
	{ path: ':lang/story/:name', component: AppComponent},
	{ path: ':lang/about',    component: AppComponent },
	{ path: ':lang/credits',    component: AppComponent },
	{ path: ':lang/stories',    component: AppComponent }*/
	{ path: '', redirectTo: "/chapter/1", pathMatch: 'full' },
	{ path: 'chapter', redirectTo: '/chapter/1', pathMatch: 'full'},
	{ path: 'chapter/:id', component: ChapterComponent},
	{ path: 'chapter/:id/context', redirectTo: '/chapter/:id/context/1', pathMatch: 'full'},
	{ path: 'chapter/:id/context/:key', component: ChapterComponent},
	{ path: 'chapter/:id/video/:key', component: ChapterComponent},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
	
}