import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from '../app.component';
import { AppService } from './../app.service';
import { AudioService } from './../audio/audio.service';
import { PreloaderService } from './../preloader/preloader.service';
import { DeviceDetectorService } from 'ngx-device-detector';

// jQuery
declare let jquery: any;
declare let $: any;
declare let Hamster: any;
declare let gtag: any;

import { TweenMax, TimelineMax, SteppedEase } from "gsap/TweenMax";
import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/minified/plugins/animation.gsap.min';

import Hammer from 'hammerjs';
import 'imports-loader?define=>false!hammerjs/hammer.min';

@Component({
	selector: 'chapter',
	templateUrl: './chapter.component.html',
	styleUrls: ['./chapter.component.scss'],
	providers: []
})
export class ChapterComponent implements OnInit {
	private _chapter: any;
	@Input() chapterIndex: any;
	@Input() chapters: any;

	controller: any = null;
	mainTimeline: any = null;
	bgTimeline: any = null;
	scrollLeft: any = 0;
	contextOpen: any = false;
	goingBack: any = false;
	modal: any = false;
	timer: any;
	ck_scroll_hzl: any = false;
	ck_instructions: any = false;
	timer_ht: any = false;
	lastResize: any = new Date();
	initWinH: any = $(window).height();
	slidesLoaded: any = false;
	onLoadPage: any;

	isNavigating: boolean = false;
	isLoaded: boolean = false;
	goback: boolean = false;
	slide: boolean = false;
	cookie: boolean = false;
	grabbing: boolean = false;
	epilogueDone: boolean = false;
	btnBlink: boolean = false;
	mute: boolean;
	textCopied: boolean = false;
	videoEp: any;
	contextMenu: any = false;

	@HostListener('window:keydown', ['$event'])
	keyEvent(event: KeyboardEvent) {
		if(!this.contextOpen){
			let distance = $(window).width() / 3;
			//right arrow
			if(event.keyCode === 39) {
				this.appService.navigateTo(distance, 400, 'swing', true);
			} 
			//left arrow
			if(event.keyCode === 37) {
				this.appService.navigateTo(-distance, 400, 'swing', true);
			}
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if(!this.appService.isMobile() && !this.appService.isTablet()){
			this.lastResize = new Date();
			setTimeout(() => {
				let resizeTime = new Date();

				if((resizeTime.getTime() - this.lastResize.getTime()) >= 200) {
					this.resetScenes();
				}
			}, 200);
		}
	}

	@HostListener('window:orientationchange', ['$event'])
	onOrientationChange(event) {
		this.lastResize = new Date();
		setTimeout(() => {
			let resizeTime = new Date();

			if(this.slidesLoaded && (resizeTime.getTime() - this.lastResize.getTime()) >= 200) {
				this.resetScenes();
			}
		}, 200);
	}

	@Input()
	set chapter(chapter: any) {
		this._chapter = chapter;

		this.resetScenes();

		// load ambient sound
		setTimeout(() => {
		  	if(this._chapter.audio.ambient) {
		  		this.loadInitAudio('ambient');
		  	}
		  }, 100);

	  	// call for analitycs
	  	gtag('config', 'UA-141780583-1', {
	      'page_title' : 'Chapter '+this._chapter.id,
	      'page_path': '/chapter/'+this._chapter.id
	    });

	}

	constructor(private router: Router,
		private appService: AppService,
		private location: Location,
		private preloader: PreloaderService,
		private cookieService: CookieService,
		private audioService: AudioService,
		private deviceService: DeviceDetectorService
	) { }

	copyLink(): void {
		let copyText = document.getElementById("copyText") as HTMLInputElement;

		copyText.select();

		document.execCommand("copy");
		this.textCopied = true;

		setTimeout(() => {
			this.textCopied = false;
		}, 5000);
	}

	resetScenes(): void {
		this.preloader.setSlidesLoaded(false);

		setTimeout(() => {
			$(".timeline").scrollLeft(0);
		}, 10);

		if (this.controller) {
			
			if(this.mainTimeline) {
				this.mainTimeline.clear();
				this.mainTimeline.kill();
				this.mainTimeline = null;
			}
			if(this.bgTimeline) {
				this.bgTimeline.kill();
				this.bgTimeline = null;
			}
			
			this.audioService.stopAllAudio();

			TweenMax.killAll(); //catches callbacks
			this.controller = this.controller.destroy(true);
			this.controller = null;

			let tempSlides = this._chapter.slides.slice();
			let tempBG = this._chapter.bg;
			this._chapter.bg = null;
			this._chapter.slides = [];

			setTimeout(() => {
				this._chapter.slides = tempSlides;
				this._chapter.bg = tempBG;
				setTimeout(() => {
					this.setupSlides();
					this.loadInitAudio('ambient');
					this.loadInitAudio('unique');
				}, 100);
			}, 2000);
		} else {
			setTimeout(() => {
				this.setupSlides();
			}, 10);
		}
	}
	// load unique or ambient audio for current chapter
	loadInitAudio(type): void {
		let volume;
		let audioPath = this.appService.getShareUrl()+ '/chapters/' + (this._chapter.id-1) + '/audio/';
		if(type == 'unique') {
			volume = (this._chapter.audio.uniqueVolume ? this._chapter.audio.uniqueVolume : 1)
			this.audioService.loadAudio(3, audioPath+this._chapter.audio.unique, false, true, volume);
		} else {
			volume = (this._chapter.audio.volume ? this._chapter.audio.volume : 1);
  			this.audioService.loadAudio(0, audioPath+this._chapter.audio.ambient, true, true, volume);
  		}
	}

	ngOnInit(): void {
		//get chapter data
		if(!this.appService.isMobile()) {
			let mc = new Hammer($("#timeline")[0]);
			mc.get('swipe').set({direction: 30});
			mc.on("swiperight", (ev) => {
				this.appService.navigateTo(-$(window).width() / 3, 400, 'swing', true);
			});
			mc.on("swipeleft", (ev) => {
				this.appService.navigateTo($(window).width() / 3, 400, 'swing', true);
			});
			mc.on("panstart", (ev) => {
				this.grabbing = true;
			});
			mc.on("panmove", (ev) => {
				this.appService.pan((ev.srcEvent.movementX > 0 ? 5 : -5));
			});
			mc.on("panend", (ev) => {
				this.grabbing = false;
			});
			let tl = document.getElementById('timeline');
			if(this.appService.getOS() == "Windows" && (this.appService.getBrowser() == "Chrome" || this.appService.getBrowser() == "MS-Edge")) {
				let navigating = false;
				Hamster(tl).wheel((event, delta, deltaX, deltaY) => {  
					//vertical scroll  
					let absY = Math.abs(deltaY)
					let distance = (deltaY > 0 ? $(window).width() / 3 : -$(window).width() / 3);
					if(absY > Math.abs(deltaX) &&
					  ($(event.target).closest("chapter").length > 0)) {
					  		event.preventDefault();
					  		let distance = (deltaY > 0 ? -$(window).width() / 3 : $(window).width() / 3);

					  		this.appService.navigateTo(distance, 400, 'linear', true);
					}
				});
			} else {
				Hamster(tl).wheel((event, delta, deltaX, deltaY) =>{  
					
					let d = (Math.abs(deltaY) > Math.abs(deltaX) ? deltaY : deltaX);
					if(d !== 0 && 
					  ($(event.target).closest("chapter").length > 0)) {
					  	event.preventDefault();
						this.appService.pan((d > 0 ? 5 : -5));
					}
				});
			}
		} 
		
		this.appService.getScroll().subscribe(item => {
	  		this.scrollLeft = item;
	  		this.goback = true; 
	  	});
		
	  	this.appService.getContext().subscribe(item => {
	  		this.contextOpen = item;
	  		if(item) {
	  			if(!this.contextMenu)
		  			this._chapter.context[item-1].read = true;
	  		}
	  	});
	  	
	  	this.appService.getModal().subscribe(item => {
	  		this.modal = item;
	  	});

	  	this.appService.getMute().subscribe(item => {
	  		this.mute = item;
	  	});

	  	this.appService.getCookie().subscribe(item => {
	  		this.cookie = item;
	  	});

	  	this.preloader.getIsLoaded().subscribe(item => {
	  		this.isLoaded = item;
	  	});

	  	this.appService.getBack().subscribe(item => {
	  		this.goingBack = item;
	  	});

	  	this.appService.getEpilogueDone().subscribe(item => {
	      this.epilogueDone = item;
	    });

	    this.appService.getPage().subscribe(item => {
	      this.onLoadPage = item;
	    });

	    this.preloader.getSlidesLoaded().subscribe(item => {
	      this.slidesLoaded = item;

	      // load unique sound for current chapter
	  		if(this.cookie) {
		        if(this.isLoaded && this.slidesLoaded && this._chapter.audio.unique && this._chapter.id !== 6) {
		          	this.loadInitAudio('unique');

		            setTimeout(() => {
						this.appService.showTooltip('scroll-horizontal', 6);
					}, 4500);
		        }
		    }
	    });

	    this.appService.getVideoEp().subscribe(item => {
	      this.videoEp = item;
	    });

	    this.appService.getContextMenu().subscribe(item => {
	  		this.contextMenu = item;
	  	});
	}

	scrollToSlide(index): void {
		if(this._chapter.id == 6) {
			// load unique
			//this.loadInitAudio('unique');
			$(".timeline").animate({ scrollLeft: $(".timeline")[0].clientWidth }, 2000, 'swing', () => {});		
		} else if(this._chapter.id == 1) {
			this.appService.navigateTo($(window).width() + ($(window).height() / 100 * 150), 2000, 'swing', false);
		} else {
			this.appService.frescoTo($("#slide" + (index - 1)).position().left, false);
		}
	}

	getSlideDuration(index, v): any {
		let slideWidth = $("#slide"+index).width();

		if(v.duration && String(v.duration).indexOf('%') !== -1) 
			slideWidth = this.convertPercentage(v.duration, $("#slide"+index).width())
		
		if(v.onSlideStart) {
			return slideWidth;
		} else {
			return slideWidth / 2;
		}
	}

	convertPercentage(percent, total): any {
		if (percent && String(percent).indexOf('%') !== -1) {
			percent = percent.substring(0, percent.length - 1);
			return Math.floor(percent * total / 100);
		} else {
			return percent;
		}
	}

	setupSlides(): void {
		this.initWinH = $(window).height();
		$(".timeline").scrollLeft(0);

		this.controller = new ScrollMagic.Controller({ vertical: false, container: '.timeline' });
		
		$(this._chapter.slides).each((i, v) => {
			this.mainTimeline = new TimelineMax({ useFrames: true });
			let slideDur = this.getSlideDuration(i, v.scene);
			let offset = this.convertPercentage(v.scene.offset, slideDur);

			if(this._chapter.id == 3 && v.scene.id == "chap3fck") {
				let windowH = $(window).height();
				slideDur = (windowH * 10) - offset;
			}
			else
				slideDur = slideDur - offset;

			let scene = new ScrollMagic.Scene({
				triggerElement: "#" + (v.scene.onSlideStart && v.scene.onSlideStart === true ? "slide" : "trigger") + i,
				triggerHook: v.scene.triggerHook,
				duration: slideDur,
				offset: offset,
				reverse: true
			});

			if (v.scene.pinnedSlide) {
				scene.setPin("#slide" + i, { pushFollowers: true });
			}

			$(v.scene.tweens).each((j, val) => {
				let audioPath = this.appService.getShareUrl() + '/chapters/' + (this._chapter.id - 1) + '/audio/';
				val.styles['background-image'] = 'url(' + this.appService.getShareUrl() + '/chapters/' + (this._chapter.id - 1) + '/images/' + val.image + ')';
				val.compactStyles['background-image'] = "url(" + this.appService.getShareUrl() + '/chapters/' + (this._chapter.id - 1) + '/images/' + val.image + ")";

				val.duration = this.convertPercentage(val.duration, slideDur)

				$(val.properties).each((k, properties) => {

					properties.ease = eval(properties.ease);

					if (properties.duration) {
						properties.duration = this.convertPercentage(properties.duration, slideDur);
					}

					if (properties.delay) {
						properties.delay = this.convertPercentage(properties.delay, slideDur)
					}
					let tween;
					if(!val.startValues)
						tween = TweenMax.to("#"+val.element, (properties.duration ? properties.duration : val.duration), properties);
					else
						tween = TweenMax.fromTo("#"+val.element, (properties.duration ? properties.duration : val.duration), val.startValues, properties);

					tween.eventCallback("onComplete", () => {
						// detect epilogue
						if(val.stopScroll ) {
							this.appService.setSlide(true);
							scene.removePin(true);
						} else {
							this.appService.setSlide(false);
						}
						if(!this.contextOpen && val.audio) {
							if(this.isLoaded)
								this.audioService.loadAudio(2, audioPath+val.audio, false, true);
						} else if(!this.contextOpen && val.audioLoop && val.audioLoop.start) {
							if(this.isLoaded)
								this.audioService.loadAudio(0, audioPath+val.audioLoop.start, true, true, (val.audioLoop.startVolume ? val.audioLoop.startVolume : 1));
						}

						if(val.nextElement) {
							if(val.hideOnComplete)
								$("#"+val.element).addClass("hidden");

							$("#"+val.nextElement).removeClass("hidden");

							if(val.pin) {
								if(val.childTweens)
									scene.removePin(false);
								else
									scene.removePin("#"+val.element);
								
								scene.setPin("#"+val.nextElement);
							}
						} 

						// show btn after animation done
						if(val.btn) {
							$('#btn'+val.btn).removeClass('hidden');
						}
					}, [val,"param2"]);					

					tween.eventCallback("onReverseComplete", () => { 
						// detect epilogue
						if(val.stopScroll) {
							this.appService.setSlide(true);
						} else {
							this.appService.setSlide(false);
						}

						if(!this.contextOpen && val.audio) {
							if(this.isLoaded)
								this.audioService.loadAudio(2, audioPath+val.audio, false, true);
						} else if(!this.contextOpen && val.audioLoop && val.audioLoop.reverse) {
							if(this.isLoaded)
								this.audioService.loadAudio(0, audioPath+val.audioLoop.reverse, true, true, (val.audioLoop.reverseVolume ? val.audioLoop.reverseVolume : 1));
						}

						if(val.prevElement) {
							if(val.hideOnComplete)
								$("#"+val.element).addClass("hidden");

							$("#"+val.prevElement).removeClass("hidden");

							if(val.pin) {
								if(val.childTweens)
									scene.removePin(true);
								else
									scene.removePin("#"+val.element);

								scene.setPin("#"+val.prevElement);
							}
						}

						// show btn after animation done
						if(val.btn) {
							$('#btn'+val.btn).addClass('hidden');
						}

					}, [val,"param2"]);

					if(val.prevElement) {
						$("#"+val.element).addClass("hidden");
					}

					if(val.pin) {
						scene.setPin("#"+val.element);
					} 				
					
					this.mainTimeline.add(tween, 0);
					tween.seek(0).invalidate().restart();

					if (j == this._chapter.slides[i].scene.tweens.length - 1) {

						scene.setTween(this.mainTimeline)
							.addTo(this.controller);

						scene.update();

						this.controller.update();

						if(this._chapter.id == 6) {
							this.loadEpilogueSlide();
						} else {
							setTimeout(() => {
								this.preloader.setSlidesLoaded(true);

								if(this.cookie && this.isLoaded && this.slidesLoaded && this._chapter.audio.unique && this._chapter.id !== 6) {
							        this.loadInitAudio('unique');
							    }
							}, 100);
						}
					}
				});

				$(val.childTweens).each((l, val) => {
					val.duration = this.convertPercentage(val.duration, slideDur);
					val.styles['background-image'] = 'url(' + this.appService.getShareUrl() + '/chapters/' + (this._chapter.id - 1) + '/images/' + val.image + ')';
					val.compactStyles['background-image'] = "url(" + this.appService.getShareUrl() + '/chapters/' + (this._chapter.id - 1) + '/images/' + val.image + ")";

					$(val.properties).each((k, properties) => {
						properties.ease = eval(properties.ease);
						if (properties.duration) {
							properties.duration = this.convertPercentage(properties.duration, slideDur);
						}

						if (properties.delay) {
							properties.delay = this.convertPercentage(properties.delay, slideDur)
						}

						let tween = TweenMax.to("#" + val.element, (properties.duration ? properties.duration : val.duration), properties);

						tween.eventCallback("onComplete", () => { 
							if(val.stopScroll ) {
								scene.removePin(true);
							} 

							if(!this.contextOpen && val.audio) {
								if(this.isLoaded)
									this.audioService.loadAudio(2, audioPath+val.audio, false, true);
							} else if(!this.contextOpen && val.audioLoop && val.audioLoop.start) {
								if(this.isLoaded)
									this.audioService.loadAudio(0, audioPath+val.audioLoop.start, true, true);
							}

							if(val.nextElement) {
								if(val.hideOnComplete)
									$("#"+val.element).addClass("hidden");

								$("#"+val.nextElement).removeClass("hidden");
							}

							if(val.btn) {
								$('#btn'+val.btn).removeClass('hidden');
							}
						}, [val,"param2"]);

						tween.eventCallback("onReverseComplete", () => { 

							if(val.stopScroll ) {
								scene.setPin("#stadiumbackblue");
							} 

							if(!this.contextOpen && val.audio) {
								if(this.isLoaded)
									this.audioService.loadAudio(2, audioPath+val.audio, false, true);
							} else if(!this.contextOpen && val.audioLoop && val.audioLoop.reverse) {
								if(this.isLoaded)
									this.audioService.loadAudio(0, audioPath+val.audioLoop.reverse, true, true);
							}

							if(val.prevElement) {
								if(val.hideOnComplete)
									$("#"+val.element).addClass("hidden");

								$("#"+val.prevElement).removeClass("hidden");
							}

							if(val.btn) {
								$('#btn'+val.btn).addClass('hidden');
							}

						}, ["param1","param2"]);

						if(val.prevElement) {
							$("#"+val.element).addClass("hidden");
						}

						if(val.pin) {
							scene.removePin("#"+val.element);
							scene.setPin("#"+val.element);
						} 				
						
						this.mainTimeline.add(tween, 0);

						tween.seek(0).invalidate().restart();
					});
				});
			});

			// after last slide
			if (i == this._chapter.slides.length - 1) {
				this.setChapterPosition();
				
				this.setupBG();
				$(".btnhid").addClass('hidden');
			}
		});

		// chapter navigation for previous and next chapter 
		this.setTweenNav();
	}

	loadEpilogueSlide(): void {
		var t = TweenMax.fromTo('#banks', 1, {'background-position': '0% 50%'}, {'background-position': '100% 50%', ease: SteppedEase.config(11), repeat: -1})
		t.play();

		t = TweenMax.fromTo('#santo', 1, {'background-position': '0% 50%'}, {'background-position': '100% 50%', ease: SteppedEase.config(11), repeat: -1})
		t.play();

		t = TweenMax.fromTo('#williams', 1, {'background-position': '0% 50%'}, {'background-position': '100% 50%', ease: SteppedEase.config(11), repeat: -1})
		t.play();

		var t = TweenMax.fromTo('#jenkins', 1, {'background-position': '0% 50%'}, {'background-position': '100% 50%', ease: SteppedEase.config(11), repeat: -1})
		t.play();

		setTimeout(() => {
			this.preloader.setSlidesLoaded(true);
		}, 100)
	}

	setupBG(): void {
		if(this._chapter.bg) {
			this.bgTimeline = new TimelineMax({useFrames: true});
			let right = (this._chapter.bg.right ? this._chapter.bg.right.slice(0,-2) * $(window).height() / 100 : 0)
			let dur;
			if(this._chapter.id == 1)
				dur = $(".timeline")[0].scrollWidth - $(window).height();
			else if(this._chapter.id == 2)
				dur = $(".timeline")[0].scrollWidth - ($(window).height() * 4);
			else
				dur = $(".timeline")[0].scrollWidth;

			let scene = new ScrollMagic.Scene({
				triggerElement: "#chBG",
				triggerHook: "onLeave",
				duration: dur,
				offset: 0,
				reverse: true
			});

			$(this._chapter.bg.tweens).each((i, val) => {

				val.delay = (val.delay ? this.convertPercentage(val.delay, $(".timeline")[0].scrollWidth) : 0);
				let tween = TweenMax.to("#chBG", dur, val);

				this.bgTimeline.add(tween, 0);

				if(i == this._chapter.bg.tweens.length -1) {
					scene.setPin("#chBG");
					

					scene.setTween(this.bgTimeline)
					.addTo(this.controller);
				}
			});			
		}
	}

	setTweenNav(): void {
		$('.scrollside').each((i, v) => {
			let timeline = new TimelineMax({ useFrames: true });
			let id = $(v).attr('id').slice(5);
			let scene = new ScrollMagic.Scene({
				triggerElement: "#trigger" + id,
				duration: 1,
				offset: 0			
			});

			let proper = { properties: { visibility: "visible", delay: 0 } };
			let tween = TweenMax.to("#" + $(this).attr('id'), 1, proper, 0);
			tween.eventCallback("onStart", () => {
				if (id == "Next" && !this.goingBack) {
					this.goForward();
				} 
			}, ["param1", "param2"]);


			if (id == "Prev")
				scene.triggerHook("onLeave");
			else if (id == "Next")
				scene.triggerHook("onCenter");

			timeline.add(tween, 0);
			scene.setTween(timeline).addTo(this.controller);

		});
	}

	setChapterPosition(): void {
		let left = 0;
		this.goback = false;
		setTimeout(() => {
			left = 0;

			$(".timeline").scrollLeft(left);
			this.appService.setScroll(left);

			setTimeout(() => {
				this.appService.setBack(false);
			}, 10);
		}, 1000);
	}

	getPaddingRight(slide): string {
		return "50vw";
	}

	getTweenStyles(tween): any {
		if (!tween.styles['background-image']) {
			tween.styles['background-image'] = this.appService.getShareUrl() + '/chapters/' + (this._chapter.id - 1) + '/images/' + tween.image;
			tween.compactStyles['background-image'] = "url(" + this.appService.getShareUrl() + '/chapters/' + (this._chapter.id - 1) + '/images/' + tween.image + ")";

			return (this.contextOpen ? tween.compactStyles : tween.styles);
		} else {
			return (this.contextOpen ? tween.compactStyles : tween.styles);
		}
	}

	goBack(): void {
		let id = parseInt(this._chapter.id) - 1;
		this.appService.setBack("prev");
		this.appService.setChapter(id - 1);
		this.location.replaceState('chapter/' + (id));
		this.preloader.loadAssets(id, this.chapters[id]).then((resp) => {});
	}

	goForward(): void {
		let id = parseInt(this._chapter.id) + 1;
		//this.appService.setBack("next");
		this.appService.setChapter(id - 1);
		this.location.replaceState('chapter/' + (id));
		this.preloader.loadAssets(id - 1, this.chapters[id-1]).then((resp) => {});
	}

	openModal(btn, type, cid?:number): void {
		// open video player: level 2
		// mute sound
		// call for analitycs

		let id = btn.id;
		

		this.audioService.controlSound(true);
		if (type == 'video') {
			console.log("opening modal");
			this.appService.setActiveVideo(id);
			this.appService.setVideoEp(cid);
			this.location.replaceState('chapter/' + this.videoEp + '/video/' + id);
			gtag('config', 'UA-141780583-1', {
		      'page_title' : 'Chapter '+this.videoEp,
		      'page_path': 'chapter/' + this.videoEp + '/video/' + id
		    });
		} else { // open context: level 3
			this._chapter.context[id-1].read = true;
			this.appService.setBtnCtxt(true);
			this.appService.toggleContext(id);
			// load tooltip
			this.appService.loadCxtTooltip();
			setTimeout(() => {
				this.appService.loadIframe();
			}, 100);
			this.location.replaceState('chapter/' + this._chapter.id + '/context/' + id);
			gtag('config', 'UA-141780583-1', {
		      'page_title' : 'Chapter '+this._chapter.id,
		      'page_path': 'chapter/' + this._chapter.id + '/context/' + id
		    });
		}
	}

	closeModal(): void {
		this.appService.setModal(false);
		// unmute sound or not
		//this.audioService.resumeContext();
		this.audioService.controlSound(this.mute);
		this.location.replaceState('chapter/' + this._chapter.id);
	}

	showInstructions(): void {
		this.ck_instructions = (this.cookieService.get("instructions") == "true" ? true : false);
		if (!this.ck_instructions) {
			setTimeout(() => {
				this.appService.showTooltip('instructions', 10);
			}, 1000);
		}
	}
}
