import { Injectable, ApplicationRef, Output, EventEmitter }   					from '@angular/core';
import { Http } 				from '@angular/http';
import { HttpHeaders, HttpClient }		    from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { AppService } 									from '../app.service';

import { Howl } 											from 'howler';



declare let jquery:any;
declare let $ :any;

const imgHeaders: any = new HttpHeaders({
							'Content-Type': 'image',
							'Access-Control-Allow-Origin': '*'
						});

const audioHeaders: any = new HttpHeaders({
							'Content-Type': 'audio/mpeg',
							'Access-Control-Allow-Origin': '*'
						});

@Injectable()
export class PreloaderService {
	@Output() isLoaded: EventEmitter<boolean> = new EventEmitter(false);
	@Output() slidesLoaded: EventEmitter<boolean> = new EventEmitter(false);
	private iconFolder: string = "";
	private assetFolder: string = "";
	private isIconsLoaded: boolean = false;
	private macAudio: Array<any> = [];
	private macInit: boolean = false;

	constructor(private http: Http, private appService: AppService, private changeDetect: ApplicationRef ) {
		this.iconFolder = this.appService.getBaseUrl() + '/assets/img/';
	}

	//lets components subscribe
	getIsLoaded(): EventEmitter<any> {
		return this.isLoaded;
	}

	getSlidesLoaded(): EventEmitter<any> {
		return this.slidesLoaded;
	}

	setSlidesLoaded(view): void {
		this.slidesLoaded.emit(view)
	}

	//lets subscribed components know changes
	setIsLoaded(view): void {
		this.isLoaded.emit(view);
	}

	//wrapper function for entire preload process
	loadAssets(chapterIndex, chapter): Promise<any> {
		this.setIsLoaded(false);//let view know assets aren't loaded
		this.macAudio = [];

		this.assetFolder = this.appService.getShareUrl() + '/chapters/'+chapterIndex;

		//load icons once
		if(!this.isIconsLoaded) {
			return this.appService.getIcons().then((resp: any) => {
				return this.loadIcons(0, resp).then((iconsLoaded: boolean) => {
					this.isIconsLoaded = iconsLoaded;

					return this.loadChapter(chapter).then((chapterLoaded: boolean) => {
						//sets loaded to true if succeed
						this.setIsLoaded(true);
					});
				})
			});
		} else {
			return this.loadChapter(chapter).then((chapterLoaded: boolean) => {
				//sets loaded to true if succeed
				this.setIsLoaded(true);
			});
		}
	}

	//TODO:
	loadIcons(iconIndex, icons): Promise<any> {
		if(iconIndex < icons.length) {
			return this.loadAsset(this.iconFolder + icons[iconIndex], "img").then((resp: boolean) => {
				return this.loadIcons(icons, (iconIndex+1));
			})
		} else {
			return of(true).toPromise() as any;
		}
		
	}

	loadChapter(chapter): Promise<any> {
		return this.loadIntro(chapter).then((introLoaded: boolean) => {
			return this.loadSlides(chapter.slides, 0).then((slidesLoaded: any) => {
				return of(true).toPromise() as any;
			});
		})
	}

	loadIntro(chapter): Promise<any> {
		if(chapter.intro && chapter.intro.logo != undefined){
			//load logo
			return this.loadAsset(this.assetFolder + "/images/" +chapter.intro.logo, "img").then((resp: boolean) => {
				return this.loadAsset(this.assetFolder + "/images/" +chapter.intro.logo_menu, "img").then((resp: boolean) => {
					//load intro audio
					return this.loadAsset(this.assetFolder + "/audio/" +chapter.audio.ambient, "audio").then((resp: boolean) => {
						return of(true).toPromise() as any;					
					});
				});
			});
		} else {
			return of(true).toPromise() as any;
		}
	}

	loadSlides(slides, slideIndex): Promise<any> {
		if(slideIndex < slides.length) {
			if(slides[slideIndex].scene.bg != undefined) {
				return this.loadAsset(this.assetFolder + "/images/bg/" +slides[slideIndex].scene.bg, "img").then((resp: boolean) => {
					return this.loadTweens(slides[slideIndex].scene.tweens, 0).then((resp: boolean) => {
						return this.loadSlides(slides, (slideIndex+1));
					});
				})
			} else {
				return this.loadTweens(slides[slideIndex].scene.tweens, 0).then((resp: boolean) => {
					return this.loadSlides(slides, (slideIndex+1));
				});
			}
		} else {
			return of(true).toPromise() as any;
		}
	}

	loadTweens(tweens, tweenIndex): Promise<any> {
		if(tweenIndex < tweens.length) {
			if(tweens[tweenIndex].image && tweens[tweenIndex].image != undefined) {// if image
				return this.loadAsset(this.assetFolder + "/images/"+tweens[tweenIndex].image, "img").then((resp: boolean) => {

					if(tweens[tweenIndex].audioLoop && tweens[tweenIndex].audioLoop.start) {//if loop
						return this.loadAsset(this.assetFolder + "/audio/"+ tweens[tweenIndex].audioLoop.start, "audio").then((resp: boolean) => {
							
							if(tweens[tweenIndex].audio && tweens[tweenIndex].audio != undefined) {
								return this.loadAsset(this.assetFolder + "/audio/"+ tweens[tweenIndex].audio, "audio").then((resp: boolean) => {
									return this.loadTweens(tweens, (tweenIndex+1));
								});
							} else {
								return this.loadTweens(tweens, (tweenIndex+1));
							}
							
						});
					} else if(tweens[tweenIndex].audio && tweens[tweenIndex].audio != undefined) {
						return this.loadAsset(this.assetFolder + "/audio/"+ tweens[tweenIndex].audio, "audio").then((resp: boolean) => {
							return this.loadTweens(tweens, (tweenIndex+1));
						});
					} else {
						return this.loadTweens(tweens, (tweenIndex+1));
					}
					
				});
			} else {
				if(tweens[tweenIndex].audioLoop && tweens[tweenIndex].audioLoop.start && tweens[tweenIndex].audioLoop.start != undefined) {
					return this.loadAsset(this.assetFolder + "/audio/"+ tweens[tweenIndex].audioLoop.start, "audio").then((resp: boolean) => {
							
						if(tweens[tweenIndex].audio && tweens[tweenIndex].audio != undefined) {
							return this.loadAsset(this.assetFolder + "/audio/"+ tweens[tweenIndex].audio, "audio").then((resp: boolean) => {
								return this.loadTweens(tweens, (tweenIndex+1));
							});
						} else {
							return this.loadTweens(tweens, (tweenIndex+1));
						}
						
					});
				} else if(tweens[tweenIndex].audio && tweens[tweenIndex].audio != undefined) {
					return this.loadAsset(this.assetFolder + "/audio/"+ tweens[tweenIndex].audio, "audio").then((resp: boolean) => {
						return this.loadTweens(tweens, (tweenIndex+1));
					});
				} else {
					return this.loadTweens(tweens, (tweenIndex+1));
				}
			}
		} else {
			return of(true).toPromise() as any;
		}
	}

	loadAsset(url, type): Promise<any> {

		if(type == "img") {


			return new Promise((resolve, reject) => {

				let img = new Image();
				

		        img.onload = resolve;

		        img.onerror = resolve;

		        img.src = url;

		    });

		} else {
			url = url + this.appService.getAudioExt();

			if(this.appService.getBrowser() == "MS-Edge") {
				return this.http
					.get(url, audioHeaders)
					.toPromise()
					.then((response: any) => {
						return of(true).toPromise() as any;
					})
			}else if(!this.appService.isMac()) {
				return new Promise((resolve, reject) => {

					let audio = new Audio();

			        audio.oncanplaythrough = resolve;
			        audio.onerror = resolve;

			        audio.src = url;

			        audio.load();
			    });
			} else {
				this.macAudio.push(url);
				return of(true).toPromise() as any;
			}

			//return of(true).toPromise() as any;

			/*return audio.addEventListener('canplaythrough', (): Promise<any> => {
				return of(true).toPromise() as any;
			}, true);*/

			//return of(true).toPromise() as any;

			

			/*audio.oncanplaythrough = (() => {
				return true;
			});

			audio.onerror = (() => {
				return false;
			})	

			return of(true).toPromise() as any;*/


			/*let headers = audioHeaders;

			url = url + this.appService.getAudioExt();

			return this.http
				.get(url, headers)
				.toPromise()
				.then((response: any) => {
					return of(true).toPromise() as any;
				})*/

			/*let audio = new Howl({
				src: url,
				autoplay: false
			});

			let x = audio.once('load', () => {
				return true;
			});

			return of(x).toPromise() as any;*/
		}		
	}


	initMacAudio(): Promise<any> {
	    let allAudio = [];

		if(this.macAudio) {
			let path = this.appService.getShareUrl();
			

			for(let i= 0; i < this.macAudio.length; i++) {
				let m = new Audio(this.macAudio[i]);
				m.play();
				m.pause();
				m.currentTime = 0;

				if(i == this.macAudio.length-1) {
					this.macAudio = [];
					this.macInit = true;
					return of(true).toPromise() as any;
				}
			}
			
		}
	}
	
}