import { Injectable, ApplicationRef, Output, EventEmitter, OnInit }   					from '@angular/core';

import { Observable, of } from 'rxjs';

import { AppService } 									from '../app.service';

import { Howl } 											from 'howler';

declare let jquery:any;
declare let $ :any;

@Injectable()
export class AudioService implements OnInit {
	context: any = null;
	timeLeft: number = null;
	finished: boolean = false;
	audio: Howl;
	audios: Array<Howl> = [null, null, null, null];
	mute: boolean = false;

	// ambient sound
	ambientFinished: boolean = false;
	activeAmbientAudio: any = null;
	ambientAudio: Howl;
	panNode: any = null;
	unlockAudio: boolean = false;

	constructor(private appService: AppService, private changeDetect: ApplicationRef ) {

	}

	ngOnInit(): void {
		let AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;

		this.context = new AudioContext();

		if(this.context.state === 'suspended' && 'ontouchstart' in window) {
			$("body").once('touchstart touchend', this.context.resume());
		}

		this.audios = [null, null, null, null];
	}

	initAudio(): void {
	    let allAudio = [];

		if(allAudio) {
			let path = this.appService.getShareUrl();
			let m = new Audio(path+"/chapters/2/audio/3.4.1-crack-of-the-bat_unique.mp3");

			allAudio.push(m);

			m.play();
			m.pause();
			m.currentTime = 0;
			allAudio = null;
		}
	}

	

	//rework done
	loadAudio(index, src, loop, autoplay, volume = 1): void {

		if(!this.context) {
			this.initAudioContext();
		}
		//volume = (this.mute ? 0 : volume);
		src = src + this.appService.getAudioExt();

		//if audio is ambient and already exists
		if(loop && this.audios[index]) {
			this.crossfadeAudio(index, src, 250, volume); //crossfade
		} else {

			if(this.audios[index]) {
				this.stopAudio(index);
			}
			//create a new howler
			this.audios[index] = new Howl({
				src: src,
				loop: loop,
				volume: volume,
				autoplay: (this.mute ? false : true)
			});

			if(this.mute) {
				this.muteOneSound(this.audios[index]);
			}

			//remove audio on complete if not ambient
			if(!loop) {
				this.audios[index].once('end', () => {
					this.stopAudio(index);
				});
			}
		}
	}

	//rework done
	playAudio(index): void {
		if(!this.mute)
			this.audios[index].play();
	}

	
	//rework done
	pauseAudio(index): void {
		this.audios[index].pause();
	}


	stopAudio(index): Promise<boolean> {
		if(this.audios[index]) {
			this.audios[index].pause();
			this.audios[index].unload();

			this.audios[index] = null;

			return of(true).toPromise() as any;
		} else {
			return of(false).toPromise() as any;
		}
	}

	stopAllAudio(index = 0): void {
		this.stopAudio(index).then((resp) => {
			if(index < this.audios.length) {
				this.stopAllAudio((index+1));
			} 
		});
	}

	crossfadeAudio(index, newSrc, duration, volume = 1): void {
		//todo: create new howler, given new audio
		let startVol = (this.mute ? volume : 0);

		let newAudio = new Howl({
			src: newSrc,
			loop: true,
			volume: startVol,
			autoplay: (this.mute ? false : true)
		});

		if(this.mute) {
			
		} else {
			newAudio.once('play', () => {
				//crossfade
				//console.log(this.audios[index]);
				if(!this.mute) {
					try{
						this.audios[index].fade(1, 0, duration);
					}
					catch(e) {}
					
					newAudio.fade(0, volume, duration);

					//on complete
					setTimeout(() => {
						//debugger;
						this.stopAudio(index).then((resp) => {
							if(resp == true) {
								this.audios[index] = newAudio;
							} 
						});
					}, duration);
				} else {
					this.muteOneSound(newAudio);

					this.stopAudio(index).then((resp) => {
						if(resp == true) {
							this.audios[index] = newAudio;
						}
					});
				}
			});
		}
	}

	fadeSound(index, volume){
		this.audios[index].fade(volume,1,1000);
	}

	controlSound(muting: boolean): void {
	    if(this.mute && !muting) { //unmute
	    	this.resumeContext();//resume context first
	    	for(let i = 0; i < this.audios.length; i++) {
	    		this.unmuteOneSound(this.audios[i]);
	    	}

	    	this.mute = false;
	    } else if(muting) { //mute
	    	for(let i = 0; i < this.audios.length; i++) {
	    		this.muteOneSound(this.audios[i]);

	    		if(i == this.audios.length - 1) {
	    			this.suspendContext();//suspend context last
	    		}
	    	}

	    	this.mute = true;
	    }
	    this.changeDetect.tick();
	}

	unmuteOneSound(sound): void {
		if(sound !== null) {
			sound.mute(false);
			sound.play();
		}
		
	}

	muteOneSound(sound): void {
		if(sound !== null) {
			sound.mute(true);
			sound.pause();
		}
		
	}

	initAudioContext(): void {
		let AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;

		this.context = new AudioContext();

		this.context.onstatechange = () => {
			console.log(this.context.state);
		}
	}

	resumeContext(): void {
		console.log("resume");
		if(!this.context){
			this.initAudioContext();
		} 
		try{
			this.context.resume();
		} catch(e) {
			console.log("unable to resume context");
		}
		
	}

	suspendContext(): void {
		if(!this.context) {
			this.initAudioContext();
		}
		try {
			this.context.suspend();
		} catch(e) {
			console.log("unable to suspend context");
		}
		
	}
}