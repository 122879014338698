import { NgModule }         from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG }    from '@angular/platform-browser';
import { HttpModule, Http } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { CookieService }        from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { AppComponent }     from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ChapterComponent } from './chapter/chapter.component';
import { ContextComponent } from './context/context.component';

import { AppService }       from './app.service';
import { AudioService }     from './audio/audio.service';
import { PreloaderService } from './preloader/preloader.service';

import { HammerConfig }         from './hammer.config';
import { HammerGestureConfig }  from '@angular/platform-browser';

@NgModule({
  declarations: [
    AppComponent,
    ChapterComponent,
    ContextComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [ 
    AppService, 
    AudioService, 
    PreloaderService,
    CookieService,
    {
       provide: HAMMER_GESTURE_CONFIG,
        useClass: HammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
