import { Component, OnInit, Input, HostListener } from '@angular/core';
import { RouterModule, Routes, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { CookieService } from 'ngx-cookie-service';

import { AppService } 					from './app.service';
import { PreloaderService }				from './preloader/preloader.service';
import { AudioService }				from './audio/audio.service';
import { DeviceDetectorService } from 'ngx-device-detector';

// jQuery
declare let jquery:any;
declare let $ :any;
declare let Hamster: any;
declare let gtag: any;

import { HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
export class HammerConfig extends HammerGestureConfig {
  overrides = <any> {
    pan: { direction: 30 }
  };
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  	menuOpen: boolean = false;
  	intro: boolean = false;
  	isLoaded: boolean = false;
    slidesLoaded: boolean = false;
    chapters: any = null;
  	activeChapter: any = null;
  	chapterIndex: any = null;
  	cookie: any = false;
    lastScroll: any = new Date().getTime();
    mute: boolean = false;
    contextOpen: any = false;
    modal: any = false;
    dateLapse: any;
    start: number = 1914;
    ck_sound: any = false;
    ck_start: any = false;
    ck_scroll_vtl: any = false;
    ck_back: any = false;
    ck_next: any = false;
    ck_prev: any = false;
    static: any = false;
    goingBack: any = false;
    slide: boolean = false;
    epilogueDone: boolean = false;
    instructions: boolean = false;
    videoEp: any;
    allContext: any;
    contextMenu: any = false;
 
  	constructor(private router: Router,
				private appService: AppService,
				private preloader: PreloaderService,
				private audioService: AudioService,
				private cookieService: CookieService,
				private location: Location,
        private deviceService: DeviceDetectorService
	  ) {}

   ngOnInit(): void {
     this.appService.getBrowserInfo();
     
  	 this.appService.getJSON().then(resp => {
      this.chapters = resp;

  		let url = this.router.url.split("/");
      if(url[1] == "chapter") {
        let id;
        if(url[2].indexOf('?') > 0)
          id = url[2].substring(0, url[2].indexOf('?'));
        else
          id = url[2];

        this.setActiveChapter((parseInt(id) - 1),false);
      }

      this.allContext = [];
      for (let chap of this.chapters) {
        this.allContext.push(chap.context);
      }
  	});


  	this.preloader.getIsLoaded().subscribe(item => {
  		this.isLoaded = item;
  	});

    this.preloader.getSlidesLoaded().subscribe(item => {
      if(item == false)
        setTimeout(() => {
           this.slidesLoaded = item;
         }, 10);
      else 
        this.slidesLoaded = item;
     
    });

  	this.appService.getChapter().subscribe(item => {
  		this.activeChapter = item;

  	});

    this.appService.getContext().subscribe(item => {
      this.contextOpen = item;
    });

    this.appService.getModal().subscribe(item => {
      this.modal = item;
    });

    this.appService.getBack().subscribe(item => {
      this.goingBack = item;
    });

    this.appService.getSlide().subscribe(item => {
      this.slide = item;
    });

    this.appService.getCookie().subscribe(item => {
      this.cookie = item;
    });

    this.appService.getEpilogueDone().subscribe(item => {
      this.epilogueDone = item;
    });

    this.appService.getVideoEp().subscribe(item => {
      this.videoEp = item;
    });

    this.timelapse();

    // show tooltip and create cookie for mute/sound button
    this.ck_sound = (this.cookieService.get("turn-sound") == "true" ? true : false);
    if(!this.ck_sound) {
      setTimeout(() => { 
        this.ck_sound = this.appService.showTooltip('turn-sound',3);
      }, 1000);
    }
  }

  toggleMenu(): void {
  	this.menuOpen = !this.menuOpen;
  }

  toggleMute(): void {
    this.mute = !this.mute;
    this.appService.setMute(this.mute);
    this.audioService.controlSound(this.mute);
  }

  removeIntro(): void {
    if(this.isLoaded) {
      //ini audio
      if(this.appService.isMac())
        this.preloader.initMacAudio().then(()=> {
        });

      this.appService.setCookie(true);
      let url = this.router.url.split("/");

      if(url[3] == "context") {
        this.audioService.controlSound(true);
        this.appService.loadCxtTooltip();
        // set first page
        this.appService.setPage('context');
      }

      // play video if modal are opened
      else if(url[3] == "video") {
        let video;
        if(url[4].indexOf('?') > 0)
          video = url[4].substring(0, url[4].indexOf('?'));
        else
          video = url[4];
        
        this.audioService.controlSound(true);
        this.appService.setActiveVideo(video);
        // set first page 
        this.appService.setPage('video');
        // call for analitycs
        gtag('config', 'UA-141780583-1', {
          'page_title' : 'Chapter '+this.activeChapter.id,
          'page_path': '/chapter/'+this.activeChapter.id+'/video/'+video
        });


      } else {
        // set first page
        this.appService.setPage('chapter');
        // show tooltip and create cookie for start button
        this.ck_start = (this.cookieService.get("start") == "true" ? true : false);
        if(!this.ck_start) {
          setTimeout(() => { 
            this.appService.showTooltip('start',3);
          }, 1000);
        }
        // load unique sound for current chapter
       if(this.activeChapter.audio.unique) {
          let chapID = parseInt(url[2]) - 1;
          let audioPath = this.appService.getShareUrl()+ '/chapters/' + chapID + '/audio/';
          let volume = (this.activeChapter.audio.uniqueVolume ? this.activeChapter.audio.uniqueVolume : 1)
          this.audioService.loadAudio(3, audioPath+this.activeChapter.audio.unique, false, true, volume);
        }

        setTimeout(() => {
                this.appService.showTooltip('scroll-horizontal', 6);
        }, 4500);
      }
    }
  }

  setActiveChapter(index,nav): void {
    $(".timeline").scrollLeft(0);
    this.menuOpen = false;
    let url = this.router.url.split("/");
    if(index == this.chapterIndex) {
      this.audioService.stopAllAudio();
      // load ambient sound
      let audioPath = this.appService.getShareUrl()+ '/chapters/' + (this.chapterIndex) + '/audio/';
      if(this.activeChapter.audio.ambient) {
        // detect chrome broswer: set volume to 0 before action of user
        let volume = (this.activeChapter.audio.volume ? this.activeChapter.audio.volume : 1);
        this.audioService.loadAudio(0, audioPath+this.activeChapter.audio.ambient, true, true, volume);  
      }
    }
    this.chapterIndex = index;
    this.appService.setChapter(this.chapterIndex);
    this.appService.toggleContext(false);
    this.appService.setDelayCtxt(false);

    this.preloader.loadAssets(this.chapterIndex, this.chapters[this.chapterIndex]).then((resp) => {});
    
    // remove landing page
    if(nav)
      this.appService.setCookie(true);
    
    if(url[3] == "context" && !nav) {
      let ctxt;
      if(url[4].indexOf('?') > 0)
        ctxt = url[4].substring(0, url[4].indexOf('?'));
      else
        ctxt = url[4];

      this.setActiveContext(parseInt(ctxt));
    } else if((url[1] == "chapter" && !url[3]) || (url[1] == "chapter" && nav)) {
      this.location.replaceState('chapter/'+(this.chapterIndex+1));
    }
  }

  openModal(vid,cid): void {
    // mute sound
    // call for analitycs

    this.audioService.controlSound(true);
    this.appService.setActiveVideo(vid);
    this.appService.setVideoEp(cid);
    this.location.replaceState('chapter/' + this.videoEp + '/video/' + vid);
    gtag('config', 'UA-141780583-1', {
      'page_title' : 'Chapter '+this.activeChapter.id,
      'page_path': 'chapter/'+this.videoEp+'/video/'+vid
    });
  }

  closeModal(): void {
    this.appService.setModal(false);
    // unmute sound or not
    this.audioService.controlSound(this.mute);
    this.location.replaceState('chapter/' + this.activeChapter.id);
  }

  setActiveContext(index, cid?: number, menu?: boolean): void {
    // open context
    setTimeout(() => { 
      // setup for context menu to see all context
      if(menu) {
        this.appService.setContextMenu([cid,index]);
        this.appService.toggleContext(index);
        this.chapters[cid-1].context[index-1].read = true;
        
        // mute sound
        this.audioService.controlSound(true);

        this.location.replaceState('chapter/'+cid+'/context/'+index);

        gtag('config', 'UA-141780583-1', {
          'page_title' : 'Chapter '+cid,
          'page_path': 'chapter/'+cid+'/context/'+index
        });

        // load tooltip
        this.appService.loadCxtTooltip();

        setTimeout(() => { 
          this.appService.setDelayCtxt(true);
        }, 300);
      } else {
        this.appService.toggleContext(index);
      }
      
      setTimeout(() => { 
        // create video embed
        this.appService.loadIframe();
        $('#context-content').scrollTop(0);
      }, 100);
    }, 100);

  }

  timelapse(): void {
    let end = 1969;

    // looping date
    if(this.start == end) {
      this.start = 1914;
    } else {
      this.start++;
    }
    // stop date after everything are loaded
    if(this.isLoaded) {
      this.start = 1969;
      clearTimeout(this.dateLapse);
    } else {
      this.dateLapse = setTimeout(() => { 
        this.timelapse();
      }, 250);
    }
  }

  showStatic(page, go?:boolean): void {
    this.static = page;
    if(go) {
      setTimeout(() => {
        $('#static').animate({ scrollTop: $('#static div').height() }, 1000);
      }, 100);
    }
  }

  openInstructions(): void {
    this.instructions = true;
  }

  closeInstructions(): void {
    this.instructions = false;
  }
}
