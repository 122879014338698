import { Injectable, OnInit, Output, EventEmitter, HostListener }   from '@angular/core';
import { DomSanitizer, SafeHtml } 			from '@angular/platform-browser';
import { Location } 						from '@angular/common';
import { HttpHeaders, HttpClient }		    from '@angular/common/http';
import { Router } 				from '@angular/router';
import { Http } 				from '@angular/http';

import { Observable, of } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';

import Player       from '@vimeo/player';

// jQuery
declare let jquery:any;
declare let $ :any;

const httpOptions: any = new HttpHeaders({
							'Content-Type': 'application/json',
							'Access-Control-Allow-Origin': '*'
						});

@Injectable()
export class AppService implements OnInit {
	private base_url: string = null;
	private share_url: string = null;
	private server_url: string = null;
	private navigating: boolean = false;
	headers: any = new Headers();
	data: any = null;
	currentSlide: number = 0;
	currentNext: any = 1;
	currentPrev: any;
	lastScrollTop: number = 0;
	ck_scroll_vtl: any = false;
	ck_back: any = false;
	timer_vt: any = false;
	browserInfo: any = null;
	trackPad: boolean = false;
	audioExt: any = ".ogg";
	gifExt: any = ".webp";
	lastScroll: any = new Date().getTime();

	player;

	@Output() chapter: EventEmitter<any> = new EventEmitter();
	private chapterID: any;
	@Output() scroll: EventEmitter<any> = new EventEmitter();
	@Output() isNavigating: EventEmitter<boolean> = new EventEmitter(false);	
	@Output() contextOpen: EventEmitter<any> = new EventEmitter(false);
	@Output() delaycontextOpen: EventEmitter<any> = new EventEmitter(false);
	@Output() modal: EventEmitter<any> = new EventEmitter(false);
	@Output() mute: EventEmitter<any> = new EventEmitter(false);
	@Output() goingBack: EventEmitter<any> = new EventEmitter(false);
	@Output() slide: EventEmitter<any> = new EventEmitter(false);
	@Output() cookie: EventEmitter<any> = new EventEmitter(false);
	@Output() epilogueDone: EventEmitter<any> = new EventEmitter(false);
	@Output() onLoadPage: EventEmitter<any> = new EventEmitter();
	@Output() hideBtnCtxt: EventEmitter<any> = new EventEmitter(false);
	@Output() videoEp: EventEmitter<any> = new EventEmitter();
	@Output() contextMenu: EventEmitter<any> = new EventEmitter(false);

	constructor(private http: Http,
				private httpClient: HttpClient,
				private router: Router,
				private location: Location,
				private cookieService: CookieService,
				private deviceService: DeviceDetectorService,
				public sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.getBaseUrl();

		this.headers = new HttpHeaders().set('Access-Control-Allow-Origin', "*");

		this.getBrowserInfo();
	}

	/************* URL FUNCTIONS *********************/

	getBaseUrl(): string {
		if(this.base_url == null) {
			// define base_url
			let getUrl = window.location;
			let temp =  getUrl.pathname.split('/');

			if(getUrl.host == "localhost:4200") {
			  // ng serve
			  this.base_url = "https://www.1969chicagocubs.com";
			} else {
			  // ng build
			  this.base_url = "https://www.1969chicagocubs.com/dist";
			}
		}
		return this.base_url;
	}



	getShareUrl(): string {
		if(this.share_url == null) {
			// define base_url
			let getUrl = window.location;
			let temp =  getUrl.pathname.split('/');

			this.share_url = "https://www.1969chicagocubs.com";
		}
		return this.share_url;
	}

	getServerUrl(): string {
		if(this.server_url == null) {
			let getUrl = window.location;
			let temp =  getUrl.pathname.split('/');

			this.server_url = "";
		}
		return this.server_url;
	}

	/********** END OF URL FUNCTIONS **************/

	

	//**************** DATA RETRIEVAL ************/

	getJSON(): Promise<any> {

		if(!this.data) {
			return this.http
				.get(this.getBaseUrl() + "/assets/json/chapters.json", httpOptions)
				.toPromise()
				.then((response: any) => {
					this.data = JSON.parse(response._body);
					return this.data.chapters.slice() as any;
				});
		} else {
			return of(this.data.chapters.slice()).toPromise() as any;
		}		
	}

	getIcons(): Promise<any> {
		if(!this.data) {
			return this.http
				.get(this.getBaseUrl()+'/assets/json/chapters.json', httpOptions)
				.toPromise()
				.then((response: any) => {
					this.data = JSON.parse(response._body);
					return this.data.icons.slice() as any;
				});
		} else {
			return of(this.data.icons.slice()).toPromise() as any;
		}	
	}

	getBrowserInfo(): any {
		if(!this.browserInfo) {
			this.browserInfo = this.deviceService.getDeviceInfo();
			if(this.browserInfo.browser == "Safari") {
				this.audioExt = ".mp3";
				this.gifExt = ".gif";
			} else {
				this.audioExt = ".ogg";
				this.gifExt = ".webp";
			}

			if(this.deviceService.isDesktop()) {

				$("body").addClass(this.browserInfo.browser).addClass(this.browserInfo.os);

				try {
					document.createEvent("TouchEvent");
					$("body").addClass("track-pad");
					this.trackPad = true;
				} catch(e) {}
			} else {
				if(this.deviceService.isTablet()) {
					$('body').addClass('tablet');
				} else {
					$('#timeline').addClass('mobile');
				}
				
				if(this.browserInfo.os == "iOS")
					this.audioExt = ".mp3";
			}

			return this.browserInfo;
		} else {
			return this.browserInfo;
		}
	}

	isMobile(): any {
		return this.deviceService.isMobile();
	}

	isTablet(): any {
		return this.deviceService.isTablet();
	}

	getOS(): any {
		return this.browserInfo.os;
	}

	isMac(): any {
		if(this.browserInfo.browser == "Safari" || 
			(this.isMobile() && this.getOS() == "iOS") || 
			(this.isTablet() && this.getOS() == "Mac")) {			
			return true;
		} else {
			return false;
		}
	}

	getBrowser(): any {
		return this.browserInfo.browser;
	}

	frescoTo(pos, playAnim: boolean, quick?: boolean, context?:boolean): void {	
		if(!this.navigating) {
			this.setIsNavigating(true);
			let newPos;

			newPos = pos + $(".timeline").scrollLeft();
			
			let speed = (quick ? 400 : 2000);
			$(".timeline").animate({ scrollLeft: newPos }, speed, 'swing', () => {
				this.setScroll(newPos);
				this.setIsNavigating(false);
			});		
		}
	}

	navigateTo(pos, duration, easing: string = 'swing', relative: boolean = false): void {
		if(!this.navigating) {
			this.setIsNavigating(true);
			//let increment = $(window).height() / 10;
			//let newPos = $(".timeline").scrollLeft() + ((deltaY > 0 ? 1 : -1)*increment);
			if(relative){
				pos = $(".timeline").scrollLeft() + pos;
			} 

			$(".timeline").animate({ scrollLeft: pos }, duration, easing, () => {
				this.setScroll(pos);
				this.setIsNavigating(false);
			});
		}
	}

	pan(x): void {
		if(this.browserInfo.os == "Windows") {
			if(this.browserInfo.browser == "Firefox")
				$(".timeline").scrollLeft($(".timeline").scrollLeft() - (x*2.5));
			else if(this.browserInfo.browser == "Chrome"){
				//let curScroll = new Date().getTime()
				$(".timeline").scrollLeft($(".timeline").scrollLeft() - (x*1.5));
			}
			else if(this.browserInfo.browser == "MS-Edge")
				$(".timeline").scrollLeft($(".timeline").scrollLeft() - (x));
		} else{
			$(".timeline").scrollLeft($(".timeline").scrollLeft() - (x*2));
		} 
	}

	handleMove(slide, context?: any): void {
		this.currentSlide = slide;
		//this.frescoTo(this.getPos(this.currentSlide+1), true, false, true);
		this.toggleContext(this.currentSlide);
		
		if(context) { 
			this.location.replaceState('chapter/'+(context[0])+'/context/'+(this.currentSlide));
		} else {
			this.location.replaceState('chapter/'+(this.chapterID+1)+'/context/'+(this.currentSlide));
		}
	}

	getPos(id) {
		let slidePos = Math.round($("#btn"+id).parent().parent().parent().position().left),
			btnPos = Math.round($("#btn"+id).parent().position().left),
			winWidth = Math.round($(window).innerWidth() / 2),
			pos = (slidePos + btnPos) - winWidth;

		return pos;
	}

	getHoverElement(e): any {
		let clname, elem;
		clname = e.target.parentElement.className;

		if(clname == "text" || clname == "image") {
			elem = e.target.parentElement.parentElement.parentElement;
		} else if(clname == "blk") {
			elem = e.target.parentElement.parentElement;
		} else if(clname == "content-info") {
			elem = e.target.parentElement;
		} else {
			clname = "no class";
			elem = e.target;
		}

		return elem;
	}

	playVideo(id): void {
		// remove play buttton
		$('#c'+id).remove();
		$('#v_id_'+id).css('display','block');
		$('#v_id_'+id).html('<iframe src="https://www.youtube.com/embed/'+id+'" frameborder="0" allowfullscreen></iframe>');

	}

	loadIframe(): void {
		// for soundcloud iframe
		let elems = $('.blk div.audio'),
			i,
			url;

		if(elems) {
			elems.each(function( index ) {
				var className = $(this).attr('class');
				// detect video embed
				url = $(this).text();
				$(this).html('<iframe src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/'+url+'&color=%23ffc425&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>');
			});
		}
	}

	showTooltip(name,time): any {
		// create cookie
		$('.'+name).addClass('show');
		let cookie;
		setTimeout(() => { 
	        $('.'+name).removeClass('show');
	        if(name != 'scroll-horizontal')
				cookie = this.cookieService.set(name, "true");
	    }, time*1000);
	}

	loadCxtTooltip(): void {

      // show tooltip and create cookie for vertical scrolling
      this.ck_scroll_vtl = (this.cookieService.get("scroll-vertical") == "true" ? true : false);
      if(!this.ck_scroll_vtl) {
      	let count = 1;
		let total = 0;
		setTimeout(() => { 
			this.showTooltip('scroll-vertical',6);
        }, 1000);
      }
  	}

  	setActiveVideo(index): void {
	    this.setModal(index);
	    setTimeout(() => {
	    	$('#video-modal').html('<iframe src="https://streamable.com/m/'+index+'" frameborder="0" allowfullscreen></iframe>');
	    }, 100);

	}

	getWindowWidth(): string {
		return $(window).width() + "px";
	}

	//for injecting HTML into the DOM
	sanitize(content): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

	sanitizeUrl(link): any {
		return this.sanitizer.bypassSecurityTrustUrl(link);
	}

	getAudioExt(): any {
		return this.audioExt;
	}

	getGifExt(): any {
		return this.gifExt;
	}

	setIsNavigating(val): void {
		this.isNavigating.emit(val);
		this.navigating = val;
	}

	getIsNavigating(): EventEmitter<any> {
		return this.isNavigating;
	}

	setEpilogueDone(val): void {
		this.epilogueDone.emit(true);
	}

	getEpilogueDone(): EventEmitter<any> {
		return this.epilogueDone;
	}

	setChapter(id): void {
		this.chapterID = id; //already have access to object in data
		this.chapter.emit(this.data.chapters[id]);
	}

	getChapter(): EventEmitter<any> {
		return this.chapter;
	}

	setScroll(val): void {
		this.scroll.emit(val);
	}

	getScroll(): EventEmitter<any> {
		return this.scroll;
	}

	toggleContext(val): void {
		this.contextOpen.emit(val);
	}

	getContext(): EventEmitter<any> {
		return this.contextOpen;
	}

	setModal(val): void {
		this.modal.emit(val);
	}

	getModal(): EventEmitter<any> {
		return this.modal;
	}

	setMute(val): void {
		this.mute.emit(val);
	}

	getMute(): EventEmitter<any> {
		return this.mute;
	}

	setBack(val): void {
		this.goingBack.emit(val);
	}

	getBack(): EventEmitter<any> {
		return this.goingBack;
	}

	setSlide(val): void {
		this.slide.emit(val);
	}

	getSlide(): EventEmitter<any> {
		return this.slide;
	}

	setDelayCtxt(val): void {
		this.delaycontextOpen.emit(val);
	}

	getDelayCtxt(): EventEmitter<any> {
		return this.delaycontextOpen;
	}

	setCookie(val): void {
		this.cookie.emit(val);
	}

	getCookie(): EventEmitter<any> {
		return this.cookie;
	}

	setPage(val): void {
		this.onLoadPage.emit(val);
	}

	getPage(): EventEmitter<any> {
		return this.onLoadPage;
	}

	setBtnCtxt(val): void {
		this.hideBtnCtxt.emit(val);
	}

	getBtnCtxt(): EventEmitter<any> {
		return this.hideBtnCtxt;
	}

	setVideoEp(val): void {
		this.videoEp.emit(val);
	}

	getVideoEp(): EventEmitter<any> {
		return this.videoEp;
	}

	setContextMenu(val): void {
		this.contextMenu.emit(val);
	}

	getContextMenu(): EventEmitter<any> {
		return this.contextMenu;
	}
}
